// ** Next Imports
import NextImage from 'next/image'

// ** MUI Imports
import Box from '@mui/material/Box'

// ** Icon imports

import { can, Permissions } from '@1kinlabs/can'
import { styled } from '@1kit/ui/theme'
import SearchIcon from '@mui/icons-material/Search'
import ViewGridOutline from 'mdi-material-ui/ViewGridOutline'
import PersonIcon from '@mui/icons-material/Person'
import discordLogo from 'public/images/logos/discord.svg'
import { TelegramLogo } from '@1kit/ui/icons/TelegramLogo'
import Games from '@mui/icons-material/VideogameAsset'
import { arenaUrl } from '@1kit/ui/env'
import { GridLogoIcon } from '@1kit/ui/svg/GridLogoIcon'
import { Icon } from '@1kit/ui/atoms/Icon'

const GridIcon = styled(({ className }) => (
  <Icon className={className}>
    <GridLogoIcon />
  </Icon>
))`
  width: 24px;
  height: 24px;
`

function DiscordIcon() {
  return (
    <Box
      sx={{
        width: '24px',
        height: '24px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <NextImage src={discordLogo} alt="discord icon" width={20} height={20} />
    </Box>
  )
}

const navigation = (user) => [
  {
    title: 'Discover',
    icon: SearchIcon,
    path: '/',
  },
  {
    title: 'My Collection',
    icon: ViewGridOutline,
    path: '/my-collection',
    disabled: !user,
  },
  {
    title: 'My Profile',
    icon: PersonIcon,
    path: '/profile',
    disabled: !user,
  },
  {
    title: 'Discord',
    icon: DiscordIcon,
    openInNewTab: true,
    path: 'https://discord.gg/sqxD4PeU2s',
  },
  {
    title: 'Telegram',
    icon: TelegramLogo,
    openInNewTab: true,
    path: 'https://t.me/gr1dnetwork',
  },
  {
    title: 'Partners > Games',
    path: '/partners/games',
    icon: Games,
    hidden: !can(user?.role, Permissions.GAMES_QUERY_ACCOUNT),
  },
  {
    title: 'Partners > Rewards',
    path: '/partners/rewards',
    icon: Games,
    hidden: !can(user?.role, Permissions.REWARDS_QUERY_ACCOUNT),
  },
  {
    title: 'GR1D Arena',
    icon: GridIcon,
    path: arenaUrl(),
  },
]

export default navigation
